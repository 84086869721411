<template>
    <div class="introduction_box">
        <div class="top_image"></div>
        <div class="bottom_content">
            <div class="left_box">
                <div class="ribbon" data-v-0ff4face=""></div>
                <div class="x-line" data-v-0ff4face=""></div>
                <div class="y-line" data-v-0ff4face=""></div>
                <div class="text_content">
                    <div class="content_title">Empowering precision in every decision</div>
                    <div class="content_text">At Contrimetric, we believe that behind every metric lies an opportunity
                        to create impactful
                        change. Our journey began with a vision: to transform complex data into clear, actionable
                        insights that empower businesses to reach new heights. Through our pioneering methodologies and
                        cutting-edge technology, we help organizations not only navigate the data landscape but thrive
                        within it.</div>
                </div>
            </div>
            <div class="right_box">
                <div class="box_item">
                    <div class="box_item_top">
                        <img src="../../assets/images/shiming.png" alt="">
                        <span>Our mission</span>
                    </div>
                    <div class="line" data-v-0ff4face="">
                        <div class="orange_line" data-v-0ff4face=""></div>
                        <div class="gray_line" data-v-0ff4face=""></div>
                    </div>
                    <div class="box_item_bottom">
                        Contrimetric was founded to bridge the gap between data complexity and strategic clarity. Our
                        mission is to provide data-driven solutions that enable our clients to make precise,
                        well-informed decisions with confidence.
                    </div>
                </div>
                <div class="box_item">
                    <div class="box_item_top">
                        <img src="../../assets/images/jiazhig.png" alt="">
                        <span>Our values</span>
                    </div>
                    <div class="line" data-v-0ff4face="">
                        <div class="orange_line" data-v-0ff4face=""></div>
                        <div class="gray_line" data-v-0ff4face=""></div>
                    </div>
                    <div class="box_item_bottom">
                        Integrity, innovation, and impact drive everything we do at Contrimetric. We are committed to
                        delivering solutions that respect the confidentiality, accuracy, and strategic goals of each
                        client.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'>
@import "../../assets/css/minix.scss";

.introduction_box {
    position: relative;
    @include responsive-width(1200px);

    .top_image {
        height: 380px;
        background: url(../../assets/images/about_top.png) center no-repeat;
        background-size: cover;
    }

    .bottom_content {
        display: flex;
        width: 100%;

        .left_box {
            background: #1da6b8;
            color: #fff;
            position: relative;
            transition: all ease-out .5s;
            margin-top: -120px;
            flex: 0 0 45%;

            .ribbon {
                width: 100%;
                height: 12px;
                background: linear-gradient(270deg, rgba(148, 205, 255, 0) 0%, #19bcd1 100%);
                position: absolute;
                top: 0;
            }

            .x-line {
                width: 100%;
                height: 1px;
                background: #ffffff;
                opacity: .1;
                position: absolute;
                top: 30px;
                left: 0;
            }

            .y-line {
                width: 1px;
                height: 100%;
                background: #ffffff;
                opacity: .1;
                position: absolute;
                left: 18px;
                top: 0;
            }

            .text_content {
                padding: 60px 40px;
                transition: all ease-out .5s;
                text-align: justify;

                .content_title {
                    font-size: 28px;
                    font-family: AlibabaPuHuiTi_2_65_Medium;
                    line-height: 40px;
                    margin-bottom: 30px;
                }

                .content_text {
                    font-size: 16px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    line-height: 32px;
                }
            }
        }

        .right_box {
            background: #eef2fb;
            display: flex;
            flex-wrap: wrap;
            padding: 50px;
            transition: all ease-out .5s;

            .box_item {
                width: 100%;

                &:nth-child(1) {
                    margin-bottom: 20px;
                }

                .box_item_top {
                    display: flex;
                    align-items: center;

                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 6px;
                    }
                }

                .line {
                    display: flex;
                    margin: 7px 0 12px;

                    .orange_line {
                        width: 45px;
                        height: 1px;
                        background: #de6239;
                    }

                    .gray_line {
                        width: 79px;
                        height: 1px;
                        background: #dbe6f3;
                    }
                }

                .box_item_bottom {
                    font-size: 14px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #3d3d3d;
                    line-height: 20px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .introduction_box {
        padding: 0;
        margin-top: 20px;

        .top_image {
            display: none;
        }

        .bottom_content {
            flex-direction: column;

            .left_box {
                flex: 1;
                margin-top: 0;

                .text_content {
                    .content_title {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}
</style>