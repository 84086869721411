<template>
    <div class="future_box">
        <div class="future_title">
            The future of Contrimetric in research evaluation
        </div>
        <div class="future_list">
            <div v-for="(item, index) in futureData" :key="index" class="future_item">{{ item.content }}</div>
        </div>
    </div>
</template>

<script>
import { futureData } from "./data"

export default {
    data() {
        return {
            futureData
        }
    }
}
</script>

<style lang='scss'>
.future_box {
    .future_title {
        font-size: 30px;
        color: black;
        font-weight: bold;
        margin-top: 80px;
        margin-bottom: 30px;
    }

    .future_list {
        .future_item {
            font-size: 16px;
            margin: 10px 0;
            line-height: 1.5;
        }
    }
}

@media (max-width: 768px) {
    .future_box {
        padding: 0 20px;

        .future_title {
            font-size: 24px;
            margin-top: 30px;
        }
    }
}
</style>