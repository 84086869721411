<template>
    <div class="scholarly_box">
        <div class="reviews_2nd_level_header">
            <img src="../../assets/images/reviews_2nd_level_footer.png" alt="">
        </div>
        <div class="reviews_2nd_level_content">
            <div class="reviews_2nd_level_title">Contrimetric – Redefining scholarly impacts!</div>
            <div class="reviews_2nd_level_text">Contrimetric represents an innovative approach within the field of
                bibliometrics, focusing on a unique
                aspect of academic contributions: the individual impact of each article relative to others within its
                publication year. The term "Contrimetric" itself is derived from <span class="storng_text">Contribution
                    Metric</span>, emphasizing its
                role in assessing how each scholarly work contributes to its field, beyond traditional metrics.</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'>
.scholarly_box {
    margin-top: 80px;
    border-radius: 10px;
    display: flex;
    align-items: flex-end;
    padding: 0 72px;
    background: url(../../assets/images/bg_glassy_second.jpg) no-repeat;

    .reviews_2nd_level_header {
        flex: 0 0 50%;
        margin-right: 96px;

        img {
            width: 100%;
        }
    }

    .reviews_2nd_level_content {
        margin-bottom: 50px;

        .reviews_2nd_level_title {
            font-size: 30px;
            line-height: 1.5;
            margin-bottom: 20px;
            font-weight: bold;
        }

        .reviews_2nd_level_text {
            font-size: 16px;
            line-height: 24px;

            .storng_text {
                font-weight: bold;
            }
        }
    }
}

@media (max-width: 768px) {
    .scholarly_box {
        flex-direction: column;
        padding: 20px;
        background-size: cover;
        background-position: center center;

        .reviews_2nd_level_header {
            img {
                width: 70%;
            }
        }

        .reviews_2nd_level_content {
            margin-top: 30px;
            margin-bottom: 20px;

            .reviews_2nd_level_title {
                font-size: 24px;
                line-height: 1.3;
            }

            .reviews_2nd_level_text {
                line-height: 1.5;
            }
        }
    }
}
</style>