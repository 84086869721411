<template>
    <div class="approach_box">
        <div class="approach_title">Our approach</div>
        <div class="approach_content">At Contrimetric, we delve deep to understand these narratives, revealing insights
            that others might
            overlook. Our team of experts combines rigorous analytics with an innovative mindset to deliver insights
            tailored to our clients’ unique challenges and goals.</div>
        <div class="approach_list">
            <div class="approach_item">
                <div class="approach_item_img">
                    <img src="../../assets/images/Smiley-face-3.svg" alt="">
                </div>
                <div class="approach_item_title">
                    Data integrity first
                </div>
                <div class="approach_item_content">
                    We prioritize data quality, ensuring that every insight is as accurate as it is relevant.
                </div>
            </div>

            <div class="approach_item">
                <div class="approach_item_img">
                    <img src="../../assets/images/Clock-4.svg" alt="">
                </div>
                <div class="approach_item_title">
                    Insightful analytics
                </div>
                <div class="approach_item_content">
                    Beyond numbers, we uncover trends, patterns, and predictions that drive success.
                </div>
            </div>
            <div class="approach_item">
                <div class="approach_item_img">
                    <img src="../../assets/images/Web-1.svg" alt="">
                </div>
                <div class="approach_item_title">
                    Client-centered solutions
                </div>
                <div class="approach_item_content">
                    Your success is our mission. Our solutions are crafted to meet your specific needs and achieve
                    tangible outcomes.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'>
.approach_box {
    margin-top: 80px;

    .approach_title {
        font-size: 30px;
        color: black;
        font-weight: bold;
        text-align: left;
    }

    .approach_content {
        line-height: 1.5;
        text-align: left;
        font-size: 16px;
        margin-top: 30px;
        margin-bottom: 60px;
    }

    .approach_list {
        display: flex;
        justify-content: space-between;

        .approach_item {
            position: relative;
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            max-width: 350px;
            height: 330px;
            overflow: hidden;
            padding: 40px 20px;
            box-shadow: 0px -1px 5px 4px #00000008;
            border-radius: 15px;

            &::after {
                width: 100%;
                min-height: 300px;
                position: absolute;
                left: 73%;
                content: "";
                background: linear-gradient(200deg, rgba(9, 0, 44, 0.12) 14.69%, rgba(255, 255, 255, 0) 78.55%);
                opacity: 0.3;
                transform: rotate(319deg);
            }

            .approach_item_img,
            .approach_item_title,
            .approach_item_content {
                padding: 0 20px;
            }

            .approach_item_img {
                text-align: left;
            }

            .approach_item_title {
                font-size: 20px;
                font-weight: bold;
                margin: 20px 0;
            }

            .approach_item_content {
                font-size: 14px;
                color: #65676b;
                line-height: 2;
            }
        }
    }
}

@media (max-width: 768px) {
    .approach_box {
        margin-top: 40px;

        .approach_title,
        .approach_content {
            text-align: center !important;
        }

        .approach_title {
            font-size: 24px;
        }

        .approach_content {
            margin-bottom: 30px;
        }

        .approach_list {
            flex-direction: column;
            align-items: center;

            .approach_item {
                margin-bottom: 30px;
            }
        }
    }
}
</style>