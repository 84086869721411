<template>
    <div class="assessment_box">
        <div class="assessment_title_">The importance of ACCI in scholarly assessment</div>
        <div class="assessment_subtitle_">The ACCI offers several benefits over traditional bibliometric measures:</div>
        <div v-for="(item, index) in assessmentData" :key="index" class="assessment_list_item">
            <div class="index">{{ index + 1 }}</div>
            <div class="assessment_content">
                <div class="assessment_title">{{ item.title }}</div>
                <div class="assessment_subtitle">{{ item.content }} </div>
            </div>
            <div class="start"></div>
            <div class="array_right">
                <i class="el-icon-arrow-right"></i>
            </div>
        </div>
    </div>
</template>

<script>
import { assessmentData } from "./data"

export default {
    data() {
        return {
            assessmentData
        }
    }
}
</script>

<style lang='scss'>
$border-radius: 18px;

.assessment_box {
    margin-top: 80px;
    display: flex;
    flex-direction: column;

    .assessment_title_ {
        font-size: 30px;
        font-weight: bold;
        color: black;
    }

    .assessment_subtitle_ {
        margin-top: 30px;
        margin-bottom: 50px;
        font-size: 16px;
    }

    .assessment_list_item {
        position: relative;
        display: flex;
        align-items: center;
        padding: 20px;
        margin-bottom: 30px;
        border-radius: $border-radius;
        box-shadow: 0px 0px 3px 3px #00000008;
        transition: all .3s;

        &:hover {
            cursor: pointer;
            transform: translateX(2em) translateZ(0);
        }

        .index {
            margin-right: 48px;
            font-size: 24px;
            color: #65676b;
            text-align: right;
        }

        .assessment_content {
            display: flex;
            flex-direction: column;
            flex: 0 0 60%;
        }

        .assessment_title {
            font-size: 18px;
            font-weight: bold;
            line-height: 1.5;
        }

        .assessment_subtitle {
            color: #65676b;
            font-size: 16px;
            line-height: 1.5;
        }

        .start {
            position: absolute;
            height: 100%;
            width: 300px;
            right: 0;
            background: url(../../assets/images/start_bj.jpg);
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        .array_right {
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: 20px;
            position: absolute;
            width: 44px;
            height: 100%;
            top: 0;
            right: 0;
            border-bottom-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
            background: linear-gradient(90deg, #ffbd39 0, #fa9531 100%);
        }
    }
}


@media (max-width: 768px) {
    .assessment_box {
        margin-top: 30px;
        padding: 0 20px;

        .assessment_title_ {
            font-size: 24px;
        }

        .assessment_subtitle_{
            margin-bottom: 30px;
        }

        .assessment_list_item {
            flex-direction: column;

            .index {
                width: 100%;
                margin-right: 0;
            }

            .start {
                height: 70px;
                width: 100%;
                position: static;
            }

            .array_right {
                display: none;
            }
        }
    }
}
</style>