<template>
    <div class="citation_box">
        <div class="citation_title">The Article Citation Contribution Indicator (ACCI)</div>
        <div class="citation_content">At the core of Contrimetric’s framework is the <span class="storng_text">Article
                Citation Contribution
                Indicator (ACCI)</span>, a specific
            metric designed to measure an article’s impact in relation to the citation performance of other articles
            published in the same year. ACCI serves as a refined alternative to general citation counts by using the
            <span class="storng_text">Journal Impact Factor (JIF)</span> of each journal as a baseline for comparison,
            which allows for an
            annual,
            field-adjusted perspective on an article’s significance.
        </div>
        <div class="citation_list">
            <ul>
                <li class="citation_item">
                    <span class="storng_text">Calculation of ACCI</span>: ACCI is calculated by taking the <span
                        class="storng_text">journal impact factor</span> as
                    a benchmark to assess
                    whether an article’s citation count is above or below the average performance of other articles
                    published in the same journal and year. When a paper’s citation count exceeds the average, it earns
                    a
                    <span class="storng_text">positive ACCI value</span>, reflecting its high impact within its cohort.
                    Conversely, if it falls
                    below the
                    average, it may result in a neutral or negative ACCI, depending on how it compares to other
                    articles.
                </li>
                <li class="citation_item">
                    <span class="storng_text">Positive ACCI value</span>: An article with a positive ACCI is one that
                    has
                    been cited more
                    frequently than
                    the benchmark set by the average citation count of all articles in that same year. This indicates
                    the
                    article's heightened influence and contribution to advancing knowledge within its academic domain.
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'>
.citation_box {
    margin-top: 80px;

    .storng_text {
        font-weight: bold;
    }

    .citation_title {
        font-size: 30px;
        font-weight: bold;
        color: black;
    }

    .citation_content {
        font-size: 16px;
        line-height: 1.5;
        margin: 20px 0;
    }

    .citation_list {
        padding: 0 30px;
        background-color: white;
        border-radius: 10px;

        .citation_item {
            font-size: 16px;
            position: relative;
            list-style: disc;
            line-height: 1.5;

            &::marker {
                font-size: 24px;
            }
        }
    }
}

@media (max-width: 768px) {
    .first {
        flex-direction: column;
    }

    .citation_box {
        padding: 0 20px;
        margin-top: 30px;

        .citation_title {
            font-size: 24px;
        }
    }
}
</style>