export const assessmentData = [
    {
        title: "Temporal relevance: ",
        content: "By focusing on articles within the same publication year, ACCI accounts for the changes in citation trends, ensuring that articles are evaluated in a contextually relevant manner."
    },
    {
        title: "Field-adjusted benchmarking: ",
        content: "Using the journal’s impact factor as a baseline ensures that ACCI is sensitive to the specific norms and expectations of different academic disciplines, providing a fairer comparison across diverse fields."
    },
    {
        title: "Enhanced insight into contribution: ",
        content: "ACCI helps identify articles that make significant scholarly contributions within a short time, highlighting research that may be groundbreaking, innovative, or particularly relevant to contemporary discourse."
    }
]

export const futureData = [
    {
        content: "Contrimetric, through ACCI, holds the potential to reshape how academic contributions are evaluated by emphasizing the significance of each paper within its immediate academic context. This approach fosters a more nuanced understanding of impact, moving beyond absolute citation counts to an indicator that values relevance, timeliness, and influence."
    },
    {
        content: "In summary, Contrimetric and ACCI provide a structured, context-sensitive approach to academic impact assessment. They encourage a balanced view of research contributions that appreciates both quantitative performance and the quality of engagement within scholarly circles."
    }
]